import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetNewLanguageList = () => {
  const [newLanguage, setNewLanguage] = useState([]);
  const getNewLanguage = async () => {
    try {
      let data = await TLinkApi.get("/masters/newLanguage");
      setNewLanguage(data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getNewLanguage();
    // eslint-disable-next-line
  }, []);
  return { newLanguage, getNewLanguage };
};

export default GetNewLanguageList;
