import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  Checkbox,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  Stack,
  IconButton,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { useSnackbar } from "notistack";
import TLinkApi from "services/tlink.api";

function CustomQualification({ CloseCustomQualification, row, questionAnswer, setIsLoading }) {
  const [qualificationQuestionAnswer, setQualificationQuestionAnswer] = useState({});
  const [value, setValue] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [typeId, setTypeId] = useState(-1);
  const [canSave, setCanSave] = useState(true);

  useEffect(() => {
    setTypeId(row?.qualificationTypeId);
    setQualificationQuestionAnswer(questionAnswer);
    setQuestion(questionAnswer?.qualificationQuestion?.questionText);
    if (row?.qualificationTypeId != 3) {
      setAnswer(questionAnswer?.qualificationQuestion?.answers);
    } else {
      setAnswer(questionAnswer?.qualificationQuestion?.answers[0].qualificationAnswerDesc);
    }
  }, [questionAnswer, row]);

  let { enqueueSnackbar } = useSnackbar();

  const putCustomQualification = async () => {
    setIsLoading(true);
    let payload = qualificationQuestionAnswer;
    let obj = {};
    if (typeId != 3) {
      obj = {
        id: payload.id,
        qualificationTypeId: typeId,
        qualificationCategoryTypeId: payload.qualificationCategoryTypeId,
        qualificationQuestion: {
          id: payload.qualificationQuestion.qualificationQuestionId,
          questionText: question,
          languageId: payload.qualificationQuestion.languageId,
          answers: answer,
        },
      };
    } else {
      obj = {
        id: payload.id,
        qualificationTypeId: typeId,
        qualificationCategoryTypeId: payload.qualificationCategoryTypeId,
        qualificationQuestion: {
          id: payload.qualificationQuestion.qualificationQuestionId,
          questionText: question,
          languageId: payload.qualificationQuestion.languageId,
          answers: answer,
          answerId: payload.qualificationQuestion.answers[0].id,
        },
      };
    }
    try {
      let result = await TLinkApi.put(`/qualifications/`, obj);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    let check = false;
    if (typeId !== 3 && Array.isArray(answer)) {
      check = answer?.some((val) => val.qualificationAnswerDesc === "") || answer.length <= 0;
    } else {
      check = answer === "";
    }
    if (question === "" || check) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }, [question, answer]);

  const label = (value, hide) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
        {value} {hide ? "" : <span>* &nbsp;</span>}
      </MDBox>
    );
  };

  return (
    <Dialog
      open={true}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          padding: "0.5rem",
        },
      }}
    >
      <DialogTitle sx={{ position: "sticky", top: 0 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Custom Qualification</Typography>
          <MDBox display="flex" alignItems="center" gap="10px">
            <MDButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                CloseCustomQualification();
              }}
            >
              cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              disabled={canSave ? false : true}
              onClick={async () => {
                await putCustomQualification();
                CloseCustomQualification();
              }}
            >
              Save
            </MDButton>
          </MDBox>
        </Stack>
        <hr style={{ backgroundColor: "#CCC", height: "3px", marginTop: "0.5rem" }} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {label("Question Text")}
            <MDInput
              fullWidth
              value={question}
              onChange={(e) => {
                let payload = question;
                payload = e.target.value;
                setQuestion(payload);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 400,
                display: typeId !== 3 ? "" : "none",
              }}
            >
              {"Answers Options"}
            </Typography>

            {typeId !== 3 ? (
              Array.isArray(answer) &&
              answer.map((option, i) => {
                return (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    key={i}
                  >
                    <Stack
                      direction="row"
                      spacing={0}
                      alignItems="center"
                      sx={{ marginBottom: "0.5rem" }}
                    >
                      <Checkbox defaultChecked disabled sx={{ paddingLeft: "0px" }} />
                      <TextField
                        color="black"
                        variant="standard"
                        value={option.qualificationAnswerDesc}
                        onChange={(e) => {
                          let updatedAnswers = [...answer];
                          updatedAnswers[i].qualificationAnswerDesc = e.target.value;
                          setAnswer(updatedAnswers);
                        }}
                      />
                    </Stack>
                    <Stack direction="row" spacing={0} alignItems="center">
                      <IconButton
                        size="small"
                        onClick={async () => {
                          let payload = answer.filter((_, index) => index !== i);
                          setAnswer(payload);
                        }}
                      >
                        <DeleteOutline fontSize="small" color="error" />
                      </IconButton>
                    </Stack>
                  </Stack>
                );
              })
            ) : (
              <>
                {label("Answer Text", true)}
                <MDInput
                  fullWidth
                  variant="outlined"
                  value={answer}
                  onChange={(e) => {
                    let payload = answer;
                    payload = e.target.value;
                    setAnswer(payload);
                  }}
                />
              </>
            )}
          </Grid>
          <Grid item xs={8}>
            <TextField
              color="black"
              label="Qualification Answer"
              variant="standard"
              value={value}
              sx={{
                width: "330px",
                marginTop: "5px",
                display: typeId === 3 ? "none" : "",
              }}
              disabled={typeId === 1 && answer.length >= 1 ? true : false}
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  if (value !== "") {
                    let payload = answer;
                    let newObj = { qualificationAnswerDesc: value };
                    await payload.push(newObj);
                    setAnswer({});
                    setAnswer(payload);
                    setValue("");
                  }
                }
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
export default CustomQualification;