import { useState, useEffect } from "react";
import { Box, Button, Backdrop, CircularProgress } from "@mui/material";
import { Modal } from "./supplierMarginEdit";
import { DisplayTable } from "./supplierMarginTable";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SupplierMarginList from "hooks/SupplierMarginList/supplierMarginList";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";

function SupplierMargin() {
  const [modalOpen, setModalOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [pageSize, setPageSize] = useState(20);
  const [rows, setRows] = useState([]);
  const [hideaddrow, setHideaddrow] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  let { enqueueSnackbar } = useSnackbar();

  let { id } = useParams();

  let supplierid = id;
  const navigate = useNavigate();
  const { vendor, setVendor, isEdit, setIsEdit } = useOutletContext();
  const { suppliers, sup_id, setSup_id } = SupplierMarginList(
    supplierid,
    setIsLoading
  );

  useEffect(() => {
    let displayrows = [];
    suppliers.map((supplier) => {
      let obj = {
        id: supplier.id,
        from_cpi: supplier.from_cpi,
        to_cpi: supplier.to_cpi,
        supplier_margin: supplier.supplier_margin,
      };
      displayrows.push(obj);
    });
    setRows(displayrows);
  }, [suppliers]);

  useEffect(() => {
    if (rows.length >= 24) {
      setHideaddrow(1);
    } else if (rows.length < 24) {
      setHideaddrow(0);
    }
  }, [rows]);

  const putdata = async (data, id) => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.put(`/vendors/margin/${id}`, data);
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  const postdata = async (data) => {
    setIsLoading(false);
    try {
      let result = await TLinkApi.post(`/vendors/margin/`, data);
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
      return result;
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  const deletedata = async (targetid) => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.del(`/vendors/margin/${targetid}`, {});
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  const handleDeleteRow = (targetid) => {
    setRows(rows.filter((row) => row.id !== targetid));
    deletedata(targetid);
  };

  const handleEditRow = (targetid) => {
    var index = rows.findIndex((row) => row.id === targetid);
    setRowToEdit(index);
    setModalOpen(true);
  };

  const handleSubmit = (newRow) => {
    if (rowToEdit === null) {
      {
        let object = {
          supplierId: supplierid,
          fromCpi: newRow.from_cpi,
          toCpi: newRow.to_cpi,
          supplierMargin: Number(newRow.supplier_margin / 100),
          createdBy: 1,
          updatedBy: 1,
        };
        let response = postdata(object);
        response.then((data) => {
          newRow.id = data.data.id;
          newRow.supplier_margin = newRow.supplier_margin / 100;
          setRows([...rows, newRow]);
        });
      }
    } else {
      setRows(
        rows.map((currRow, idx) => {
          if (idx !== rowToEdit) return currRow;
          else {
            let object = {
              supplierId: supplierid,
              fromCpi: newRow.from_cpi,
              toCpi: newRow.to_cpi,
              supplierMargin: Number(newRow.supplier_margin / 100),
              createdBy: 1,
              updatedBy: 1,
            };
            putdata(object, newRow.id);
            newRow.supplier_margin = newRow.supplier_margin / 100;
            return newRow;
          }
        })
      );
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box px={2} mb={4} mt={2}>
        {!hideaddrow && (
          <Button
            sx={{
              display: "block",
              marginLeft: "auto",
              marginRight: "0",
              variant: "contained",
              color: "primary",
              fontSize: 13,
            }}
            onClick={() => setModalOpen(true)}
          >
            + Add Row
          </Button>
        )}
        <MDBox>
          <DisplayTable
            rows={rows}
            deleteRow={handleDeleteRow}
            editRow={handleEditRow}
          />
        </MDBox>
        {modalOpen && (
          <Modal
            closeModal={() => {
              setModalOpen(false);
              setRowToEdit(null);
            }}
            onSubmit={handleSubmit}
            defaultValue={rowToEdit !== null && rows[rowToEdit]}
            rows={rows}
          />
        )}
        <MDBox
          px={2}
          mt={2}
          mb={4}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="10px"
        >
          <MDButton
            sx={{ marginTop: "10px" }}
            variant="outlined"
            color="dark"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setVendor({});
              navigate("/vendors");
            }}
          >
            &nbsp;cancel
          </MDButton>
          <MDButton
            sx={{ marginTop: "10px" }}
            variant="gradient"
            color="info"
            type="submit"
            onClick={() => {
              navigate(`/vendors/${id}/languagelist/edit`);
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setVendor({});
            }}
          >
            Save
          </MDButton>
        </MDBox>
      </Box>
    </>
  );
}

export default SupplierMargin;
