import { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import MDBox from "components/MDBox";
import SidenavCollapse from "comps/Sidenav/SidenavCollapse";
import SidenavRoot from "comps/Sidenav/SidenavRoot";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import colors from "assets/theme/base/colors";
// import logo from "assets/logo/logo.png";

import logo from "assets/AccountLogos/SurveyHub.png";

import { Icon, IconButton, Stack, Tooltip } from "@mui/material";

function Sidenav({ color, width, setWidth, setMenuOpen, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const [expand, setExpand] = useState("");
  const location = useLocation();
  const collapseName = window.location.pathname.split("/")[1];
  const { info } = colors;

  const [sideNavMin, setSideNavMin] = useState(false);
  const [child, setChild] = useState(null);

  function updateSize() {
    if (window.innerWidth < 1200) {
      setSideNavMin(true);
    } else if (window.innerWidth > 1200) {
      setSideNavMin(false);
    }
  }

  window.addEventListener("resize", updateSize);

  useEffect(() => {
    updateSize();
  }, []);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(dispatch, window.innerWidth > 1200);
    }
    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  useEffect(() => {
    if (collapseName === "surveys" || collapseName === "projects") {
      setChild("Projects");
      setExpand("Projects");
    } else if (
      collapseName === "reports" ||
      collapseName === "campaigns" ||
      collapseName === "dashboard"
    ) {
      setChild("");
      setExpand("");
    } else if (
      collapseName === "vendors" ||
      collapseName === "clients" ||
      collapseName === "qualification"
    ) {
      setChild("Admin");
      setExpand("Admin");
    }
  }, [collapseName]);

  useEffect(() => {
    if (expand === "") {
      setWidth(72);
    }
  }, [expand]);

  const logoutUser = () => {
    localStorage.clear();
    window.location.reload();
  };

  const renderRoutes = routes.map(
    ({ type, name, icon, noCollapse, key, href, route, collapse }) => {
      let returnValue;
      if (type === "collapse") {
        returnValue = href ? (
          <Tooltip title={name} placement="right">
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                root={false}
                sideNavMin={sideNavMin}
                icon={icon}
                width={width}
                active={key === collapseName}
                noCollapse={noCollapse}
                onClick={() => {
                  setExpand("");
                  setChild(false);
                }}
              />
            </Link>
          </Tooltip>
        ) : (
          <Tooltip title={name} placement="right">
            <NavLink
              key={key}
              to={route}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <SidenavCollapse
                name={name}
                width={width}
                sideNavMin={sideNavMin}
                icon={icon}
                active={key === collapseName}
                onClick={() => {
                  setExpand("");
                  setChild(false);
                }}
              />
            </NavLink>
          </Tooltip>
        );
      } else if (type === "title") {
        returnValue = (
          <Tooltip title={name} placement="right">
            <div key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                sideNavMin={sideNavMin}
                root={true}
                width={width}
                dropDownIcon={expand}
                child={child}
                style={{
                  backgroundColor: child === name && info.main,
                }}
                onClick={() => {
                  setWidth(280);
                  if (expand === "") {
                    setExpand(name);
                  } else if (expand !== name) {
                    setExpand(name);
                  }
                }}
              />
            </div>
          </Tooltip>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  let renderRoutesName = routes.map(({ type, name, key, collapse }) => {
    let returnValue;
    if (type === "title" && expand === name && collapse.length > 0) {
      returnValue = (
        <MDBox
          key={key}
          display="flex"
          flexDirection="column"
          gap="20px"
          px={4}
          pt={1}
        >
          <MDBox fontSize="24px">{name}</MDBox>
          {collapse?.map((value, i) => {
            return (
              <NavLink
                to={`${value.route}`}
                key={i}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "10px",
                }}
                onClick={() => setWidth(72)}
              >
                <img
                  src={value.icon}
                  alt=""
                  style={{ width: "25px", height: "25px" }}
                />
                <MDBox
                  fontSize="16px"
                  fontWeight="500"
                  color={collapseName === value.key ? "red" : "black"}
                >
                  {value.name}
                </MDBox>
              </NavLink>
            );
          })}
        </MDBox>
      );
    }
    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{
        transparentSidenav,
        whiteSidenav,
        miniSidenav,
        darkMode,
        width,
      }}
      onClick={() => setMenuOpen(false)}
    >
      <MDBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        height="100%"
        width="100%"
      >
        <List
          style={{
            height: "98%",
            width: 72,
            borderRight: "1px solid  #D0CDCD",
          }}
        >
          <Stack justifyContent="center" alignItems="center" mb={2}>
            <MDBox
              pb={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="60px"
              borderRadius="50%"
              width="60px"
              sx={{ backgroundColor: "white !important" }}
            >
              <img src={logo} alt="" style={{ height: "80%", width: "80%" }} />
            </MDBox>
          </Stack>
          {renderRoutes}
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="auto"
            mb={2.5}
          >
            <Tooltip title="Logout" placement="right">
              <MDBox position="absolute" bottom="10px">
                <IconButton
                  color="dark"
                  onClick={() => {
                    logoutUser();
                  }}
                >
                  <Icon>logout_icon</Icon>
                </IconButton>
              </MDBox>
            </Tooltip>
          </MDBox>
        </List>

        <MDBox height="100%" pt={7} display={width === 72 ? "none" : ""}>
          {renderRoutesName}
        </MDBox>
      </MDBox>
    </SidenavRoot>
  );
}

Sidenav.defaultProps = {
  color: "info",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "headingColor",
    "error",
    "dark",
    "textColor",
  ]),
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
