import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress, Tooltip, Stack } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import GetVendorsList from "hooks/vendorsList/GetVendorsList";
import SearchInput from "components/searchInput";
import MDTypography from "components/MDTypography";
import useTableTheme from "constant/TableTheme";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { ThemeProvider } from "@mui/material";

function VendorsList() {
  const navigate = useNavigate();
  const {
    vendors,
    totalLength,
    setPage,
    setSearch,
    search,
    isLoading,
    setEntriesPerPage,
    setSortBy,
    setSortOrder,
  } = GetVendorsList();
  const { setIsEdit, setVendor } = useOutletContext();

  const tableTheme = useTableTheme();
  const [sorting, setSorting] = useState([]);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });

  useEffect(() => {
    setData(vendors);
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);

    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [vendors, pagination, sorting]);

  let columns = [
    {
      accessorKey: "id",
      header: "Vendor Id",
      headerAlign: "center",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size: 50,
    },
    {
      accessorKey: "name",
      header: "Vendor Name",
      headerAlign: "center",
      enableSorting: false, 
      size: 50,
      Cell: ({ cell }) => {
        const name = cell.getValue();
        const truncatedName = name?.length > 15 ? name.substring(0, 15) + "..." : name;
        return (
          <Tooltip title={name} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "email",
      header: "Email",
      headerAlign: "center",
      size: 50,
    },
    {
      accessorKey: "phone",
      header: "Contact Number",
      headerAlign: "center",
      size: 50,
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    enableColumnPinning: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    manualSorting: true,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate(`/vendors/${row.original.id}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    onSortingChange: setSorting,
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 335px)",
        overflowY: "auto",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack display="flex" alignItems="center" justifyContent="center" fontSize="14px" p={5}>
        No records to display
      </Stack>
    ),
  });

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox pb={3} width="100%">
        <MDTypography color="textColor" ml="1%">
          Vendors
        </MDTypography>
        <MDBox display="flex" justifyContent="center">
          <MDBox py={2} mt={3} borderRadius="5px" bgColor="white" width="98%">
            <Grid container spacing={2} pb={3}>
              <Grid
                px={2}
                item
                xs={12}
                md={12}
                display="flex"
                justifyContent="flex-end"
                flexDirection="row"
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-end", md: "center" },
                }}
                gap="15px"
              >
                <SearchInput setSearch={setSearch} search={search} />
                <MDButton
                  variant="contained"
                  color="info"
                  onClick={() => {
                    navigate("/vendors/new");
                    setVendor({});
                    setIsEdit({
                      disabled: false,
                      edit: false,
                    });
                  }}
                >
                  + New Vendor
                </MDButton>
              </Grid>
            </Grid>
            <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              table={table}
              style={{
                width: "100%",
              }}
            />
          </ThemeProvider>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

export default VendorsList;
