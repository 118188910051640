import MDBox from "components/MDBox";
import "../../global.css";
import { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import TLinkApi from "services/tlink.api";
import MDInput from "components/MDInput";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import { DisplayCard } from "./components/CardCompotents/displayCard";
import DashboardSupplierTable from "./components/SupplierTable";
import DashboardClientTable from "./components/ClientTable";
import DashboardRespondentTable from "./components/RespondentTable";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import StartIcon from "assets/images/stepperSvg/Frame.png"; 
import EPCIcon from "assets/images/stepperSvg/EPCIcon.png"; 
import CompleteIcon from "assets/images/stepperSvg/CompleteIcon.png"; 
import ConversionIcon from "assets/images/stepperSvg/ConversionIcon.png"; 
import RevenueIcon from "assets/images/stepperSvg/RevenueIcon.png"; 
import CPIIcon from "assets/images/stepperSvg/CPIIcon.png"; 

function Dashboard() {
  const [cardData, setCardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [respondentData, setRespondentData] = useState([]);

  const ranges = {
    Today: [
      moment().subtract(1, "days").startOf("day"),
      moment().subtract(0, "days").endOf("day"),
    ],
    "Last Week": [
      moment().subtract(7, "days").startOf("day"),
      moment().subtract(0, "days").endOf("day"),
    ],
    "Last Month": [
      moment().subtract(30, "days").startOf("day"),
      moment().subtract(0, "days").endOf("day"),
    ],
    "Last Year": [
      moment().subtract(365, "days").startOf("day"),
      moment().subtract(0, "days").endOf("day"),
    ],
  };

  const [timeline, setTimeLine] = useState({
    endDate: moment().subtract(0, "days").endOf("day").format("YYYY-MM-DD"),
    startDate: moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD"),
  });

  useEffect(() => {
    ApiCall();
  }, [timeline]);

  const ApiCall = async () => {
    if (Object.keys(timeline).length > 0) {
      setIsLoading(true);
      await getCardData();
      await getClientData();
      await getSupplierData();
      await getRespondentData();
    }
    setIsLoading(false);
  };

  let getCardData = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.post("/dashboards/by-range", timeline);

      setCardData(data.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  let getClientData = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.post("/dashboards/clients/data", timeline);

      setClientData(data.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  let getSupplierData = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.post("/dashboards/suppliers/data", timeline);

      setSupplierData(data.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  let getRespondentData = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.post(
        "/dashboards/respondents/status",
        timeline
      );

      setRespondentData(data.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3} marginBottom="2rem">
        <Grid
          item
          xs={12}
          md={12}
          pl={3}
          mb={1}
          style={{
            color: "#6E9633",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <MDTypography color="textColor" fontWeight="700">
            Dashboard
          </MDTypography>
          <DateRangePicker
            initialSettings={{
              endDate: moment().subtract(0, "days").endOf("day"),
              startDate: moment().subtract(1, "days").startOf("day"),
              ranges: ranges,
            }}
            alwaysShowCalendars={false}
            onCallback={(start, end, label) => {
              setTimeLine({
                startDate: moment(start._d).format("YYYY-MM-DD"),
                endDate: moment(end._d).format("YYYY-MM-DD"),
              });
            }}
          >
            <MDBox position="relative" width="300px">
              <MDInput
                ml={2}
                color="text"
                inputProps={{
                  style: {
                    height: "20px",
                    border: "1px solid #D9D9D9",
                    backgroundColor: "white",
                  },
                }}
                size="large"
                fullWidth
                value={
                  moment(timeline.startDate).format("LL") +
                  "  -  " +
                  moment(timeline.endDate).format("LL")
                }
              />
              <KeyboardArrowDownIcon
                fontSize="small"
                sx={{
                  fontSize: 20,
                  cursor: "pointer",
                  position: "absolute",
                  right: "10px",
                  top: "12px",
                }}
              />
            </MDBox>
          </DateRangePicker>
        </Grid>
        <Grid item xs={2}>
          <DisplayCard
            value={cardData?.entrants?.starts}
            name="START"
            optionIcon={
              <img src = {StartIcon} 
              width= "50px"
              />
            }
            color="99deg, #FFF -95.27%, #F39C12 -95.25%, rgba(238, 193, 137, 0.00) 115.5%"
          />
        </Grid>
        <Grid item xs={2}>
          <DisplayCard
            value={cardData?.entrants?.epc}
            name="EPC"
            optionIcon={
              <img src = {EPCIcon} 
              width= "50px"
              />
            }
            color="100deg, #12AAFF -119.32%, rgba(106, 199, 253, 0.00) 114.43%"
          />
        </Grid>
        <Grid item xs={2}>
          <DisplayCard
            value={cardData?.completes?.complete}
            name="COMPLETE"
            optionIcon={
              <img src = {CompleteIcon} 
              width= "50px"
              />
            }
            color="100deg, #D72EF2 -180.83%, rgba(215, 46, 242, 0.00) 102.32%"
          />
        </Grid>
        <Grid item xs={2}>
          <DisplayCard
            value={cardData?.completes?.conversion}
            name="CONVERSION"
            optionIcon={
              <img src = {ConversionIcon} 
              width= "50px"
              />
            }
            color="101deg, #00BA3F -102.65%, rgba(86, 209, 132, 0.00) 109.48%"
          />
        </Grid>
        <Grid item xs={2}>
          <DisplayCard
            value={cardData?.revenue?.revenue}
            name="REVENUE"
            optionIcon={
              <img src = {RevenueIcon} 
              width= "50px"
              />
            }
            color="102deg, #002FA7 -86.15%, rgba(0, 47, 167, 0.00) 104.96%"
          />
        </Grid>
        <Grid item xs={2}>
          <DisplayCard
            value={cardData?.revenue?.cpi}
            name="CPI($)"
            optionIcon={
              <img src = {CPIIcon} 
              width= "50px"
              />
            }
            color="100deg, #6a017a -180.83%, rgba(215, 46, 242, 0.00) 102.32%"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={6}>
          <DashboardClientTable rows={clientData} />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <DashboardSupplierTable rows={supplierData} />
        </Grid>
        <Grid item xs={12} md={12} lg={12} marginBottom="1rem">
          <DashboardRespondentTable rows={respondentData} />
        </Grid>
      </Grid>
    </>
  );
}
export default Dashboard;

