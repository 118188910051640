import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { boolean } from "yup/lib/locale";

const GetSurveyBasicList = (filterValue, clientId , languageId,managerId) => {
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([filterValue]);
  const [clientFilter, setClientFilter] = useState([clientId]);
  const [marketFilter, setMarketFilter] = useState([languageId]);
  const [projectManagerFilter, setProjectManagerFilter] = useState([managerId]);
  const [manualFilter,setManualFilter] = useState(true);
  const [totalLength, setTotalLength] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");

  const getSurveys = async () => {
    if (search === "") {
      setIsLoading(true);
    }

    try {
      let data = await TLinkApi.get(
        `/survey?search=${search}&filter=${filter}&page=${page + 1}&limit=${entriesPerPage}&clientFilter=${clientFilter}&sortOrder=${sortOrder}&sortBy=${sortBy}&marketFilter=${marketFilter}&projectManagerFilter=${projectManagerFilter}&manualFilter=${manualFilter}`
      );
      setSurveys(data.data.surveyData);
      setTotalLength(data.data.totalLenght);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getSurveys();
    // eslint-disable-next-line
  }, [page, search, filter, clientFilter, entriesPerPage, sortBy, sortOrder , marketFilter , projectManagerFilter ,manualFilter]);
  return {
    surveys,
    getSurveys,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setFilter,
    clientFilter,
    setClientFilter,
    marketFilter,
    setMarketFilter,
    projectManagerFilter, 
    setProjectManagerFilter,
    filter,
    setManualFilter,
    manualFilter,
    setEntriesPerPage,
    entriesPerPage,
    setSortOrder,
    setSortBy,
    sortBy,
    sortOrder,
    setIsLoading
  };
};

export default GetSurveyBasicList;
