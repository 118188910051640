import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import TLinkApi from "services/tlink.api";
import Axios from "axios";
import Auth from "services/auth";
import { useSnackbar } from "notistack";
import { Grid, IconButton, Stack, Backdrop, CircularProgress } from "@mui/material";
import PageLayout from "comps/LayoutContainers/PageLayout";
import "./index.css";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Controller, useForm } from "react-hook-form";
import { InputAdornment } from "@mui/material";
// import Logo from "assets/logo/logo.png";

import Logo from "assets/AccountLogos/SurveyHub.png";

function Basic() {
  const { enqueueSnackbar } = useSnackbar();
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: { email: "", password: "" },
  });

  const save = async (value) => {
    setIsLoading(true);
    try {
      const { data, status } = await Axios.post(
        TLinkApi.baseUrl + "/login",
        value
      );
      if (status === 200) {
        Auth.saveAuthorizationToken(data?.token);
        enqueueSnackbar("Logged in", {
          variant: "success",
        });
        navigate("/dashboard");
      }
    } catch (err) {
      setIsLoading(false);
      enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageLayout>
        <Grid
          container
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "100%",
            width: "100%",
            overflow: "auto",
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <MDBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "100vh" }}
            >
              <MDBox
                mx={2}
                p={2}
                mb={1}
                textAlign="center"
                fontWeight="400"
                sx={{
                  paddingTop: "4rem",
                  paddingBottom: "4rem",
                  width: "26rem",
                  height: "auto",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
                  borderRadius: "0.5rem",
                  background: "white",
                }}
              >
                <Stack
                  direction={"row"}
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  mb={4}
                >
                  <MDBox
                    component="img"
                    src={Logo}
                    alt="Admin App"
                    width="150px"
                    height="90px"
                    background="#00AEEF"
                  />
                </Stack>
                <MDBox textAlign="center" color="dark">
                  <h3>Welcome !</h3>
                </MDBox>
                {/* <MDBox textAlign="center">
                <p style={{ color: "#aaa", fontSize: "14px" }}>
                  Enter your credentials to login
                </p>
              </MDBox> */}
                <MDBox textAlign="left" pb={3} px={3}>
                  <form onSubmit={handleSubmit(save)} noValidate>
                    <MDBox mb={3} mt={3}>
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: "Email is required!",
                          validate: {
                            matchPattern: (v) =>
                              /\S+@\S+\.\S+/.test(v) ||
                              "Email address must be a valid address",
                          },
                        }}
                        render={({ field }) => (
                          <MDInput
                            {...field}
                            fullWidth
                            required
                            type="email"
                            id="email"
                            label="Email"
                            {...register("email")}
                            variant="outlined"
                            error={errors?.email ? true : false}
                            helperText={
                              errors?.email ? errors.email.message : null
                            }
                          />
                        )}
                      />
                    </MDBox>
                    <MDBox mb={2} className="passwordInput">
                      <Controller
                        name="password"
                        control={control}
                        rules={{
                          required: "Password is required!",
                        }}
                        render={({ field }) => (
                          <MDInput
                            {...field}
                            fullWidth
                            required
                            id="password"
                            label="Password"
                            {...register("password")}
                            type={passwordShown ? "text" : "password"}
                            variant="outlined"
                            error={errors?.password ? true : false}
                            helperText={
                              errors?.password ? errors.password.message : null
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      setPasswordShown((show) => !show)
                                    }
                                    onMouseDown={(event) => {
                                      event.preventDefault();
                                    }}
                                    edge="end"
                                  >
                                    {passwordShown ? (
                                      <VisibilityOffOutlinedIcon color="secondary" />
                                    ) : (
                                      <VisibilityOutlinedIcon color="secondary" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </MDBox>

                    <MDBox
                      mt={5}
                      // mb={1}
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      <MDButton
                        type="submit"
                        variant="contained"
                        fullWidth
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          width: "100%",
                          "&:hover": {
                            border: "5px solid rgba(255, 255, 255, 0.57)",
                          },
                        }}
                      >
                        Login
                      </MDButton>
                    </MDBox>
                  </form>
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </PageLayout>
    </>
  );
}

export default Basic;
