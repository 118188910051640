import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetLanguageCountryMap = () => {
  const [languageCountryMap, setLanguageCountryMap] = useState([]);
  const getLanguageCountryMap = async () => {
    try {
      let data = await TLinkApi.get("/masters/languagecountrymapping");
      setLanguageCountryMap(data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getLanguageCountryMap();
    // eslint-disable-next-line
  }, []);
  return { languageCountryMap, getLanguageCountryMap };
};

export default GetLanguageCountryMap;
