import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Backdrop,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TablePagination,
  styled,
  Icon,
} from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import colors from "assets/theme/base/colors";
import TLinkApi from "services/tlink.api";
import MDButton from "components/MDButton";
import CustomQualification from "./customQualificationEdit";
import CustomQualificationNew from "layouts/survey/components/Qualification/components/customQualification";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    background: "white",
    borderBottom: "1px solid #e8e8e8",
    fontSize: 14,
    fontWeight: "normal",
  },
}));

const StyledTableHeadCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    background: "white",
    color: "#637381",
    fontSize: 15,
    fontWeight: "bold",
    borderBottom: "2px solid #e8e8e8",
  },
}));

function Row(props) {
  const { row, setIsLoading } = props;
  const [open, setOpen] = React.useState(false);
  const tableStyle = { fontSize: "13.5px" };
  const [loadingAns, setLoadingAns] = React.useState(false);
  const [questionAnswer, setQuestionAnswer] = React.useState({});
  const [customQualificationOpen, setCustomQualificationOpen] = useState(false);

  const ShowCustomQualification = () => {
    setCustomQualificationOpen(true);
  };
  const CloseCustomQualification = () => {
    setCustomQualificationOpen(false);
    fetchQuestionAnswer(row.id, props.languageId);
  };

  const fetchQuestionAnswer = async (qualId, langId) => {
    setLoadingAns(true);
    try {
      let data = await TLinkApi.get(`/qualifications/${langId}/${qualId}`);
      setQuestionAnswer(data);
      setLoadingAns(false);
    } catch (e) {
      console.log(e);
      setLoadingAns(false);
    }
  };

  return (
    <>
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={async () => {
                setOpen(!open);
                if (!open) {
                  await fetchQuestionAnswer(row.id, props.languageId);
                }
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {row.id}
          </StyledTableCell>
          <StyledTableCell align="left">{row.name}</StyledTableCell>
          <StyledTableCell align="left">
            {row.qualificationCategoryTypeId === "1"
              ? "Standard"
              : row.qualificationCategoryTypeId === "2"
              ? "Custom"
              : row.qualificationCategoryTypeId === "3"
              ? "Trap"
              : "Open Ended"}
          </StyledTableCell>
          <StyledTableCell align="center">
            {parseInt(row.qualificationTypeId) === 1
              ? "Single Select"
              : parseInt(row.qualificationTypeId) === 2
              ? "Multi Select"
              : "Text Input"}
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              border: !open ? "0" : "",
            }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 2,
                  background: "white",
                  position: "relative",
                }}
              >
                <Table
                  size="small"
                  aria-label="purchases"
                  sx={{
                    background: "white",
                    "& th": {
                      fontSize: "14px",
                    },
                  }}
                >
                  <TableHead style={{ display: "table-header-group" }}>
                    <TableRow>
                      {questionAnswer && questionAnswer.qualificationQuestion && (
                        <StyledTableCell
                          sx={{ fontSize: "13.5px", fontWeight: "900" }}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={8}
                              display="flex"
                              justifyContent="flex-start"
                            >
                              &nbsp;
                              {
                                questionAnswer?.qualificationQuestion
                                  ?.questionText
                              }
                            </Grid>
                            {questionAnswer?.qualificationCategoryTypeId ==
                              2 && (
                              <Grid
                                item
                                xs={4}
                                display="flex"
                                justifyContent="flex-end"
                              >
                                <IconButton
                                  color="dark"
                                  size="small"
                                  onClick={() => {
                                    // setIsEdit(true);
                                    ShowCustomQualification();
                                  }}
                                >
                                  <Icon>border_color_icon</Icon>
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </StyledTableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {!loadingAns &&
                      questionAnswer?.qualificationQuestion?.answers.length >
                        0 ? (
                        <StyledTableCell>
                          <Grid container spacing={2}>
                            {questionAnswer.qualificationQuestion.answers.map(
                              (value, index) => (
                                <React.Fragment key={index}>
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{ paddingTop: "0px !important" }}
                                  >
                                    {Number(
                                      questionAnswer.qualificationTypeId
                                    ) === 1 ||
                                    Number(
                                      questionAnswer.qualificationTypeId
                                    ) === 2 ? (
                                      <FormControlLabel
                                        control={
                                          <Checkbox defaultChecked disabled />
                                        }
                                        label={
                                          <MDTypography
                                            variant="button"
                                            sx={{
                                              cursor: "pointer",
                                              userSelect: "none",
                                              ml: 0.5,
                                              fontSize: "13.5px",
                                            }}
                                          >
                                            {value.qualificationAnswerDesc}
                                          </MDTypography>
                                        }
                                      />
                                    ) : Number(
                                        questionAnswer.qualificationTypeId
                                      ) === 4 ? (
                                      <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                      >
                                        <MDInput
                                          type="number"
                                          autoComplete="off"
                                          variant="outlined"
                                          label="Min"
                                          disabled
                                          size="small"
                                          fullWidth
                                          required
                                        />
                                        <MDInput
                                          type="number"
                                          autoComplete="off"
                                          variant="outlined"
                                          label="Max"
                                          disabled
                                          size="small"
                                          fullWidth
                                          required
                                        />
                                      </Stack>
                                    ) : (
                                      <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                      >
                                        <MDInput
                                          autoComplete="off"
                                          value={value.qualificationAnswerDesc}
                                          variant="outlined"
                                          label="Text"
                                          size="small"
                                          fullWidth
                                          disabled
                                          required
                                        />
                                      </Stack>
                                    )}
                                  </Grid>
                                </React.Fragment>
                              )
                            )}
                          </Grid>
                        </StyledTableCell>
                      ) : (
                        <MDBox
                          p={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {loadingAns ? (
                            <CircularProgress size={20} />
                          ) : (
                            "No Data here !"
                          )}
                        </MDBox>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </StyledTableCell>
        </TableRow>
      </React.Fragment>
      {customQualificationOpen && (
        <CustomQualification
          CloseCustomQualification={CloseCustomQualification}
          row={row}
          questionAnswer={questionAnswer}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
}

export default function CollapsibleTable({
  qualification,
  isLoading,
  language,
  setLanguageId,
  languageId,
  setSearch,
  setCheck,
  categoryTypeId,
  setCategoryTypeId,
  callApi,
  setIsLoading,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { info, textColor } = colors;

  const tableStyle = { fontSize: "13px", fontWeight: "700", color: textColor.main };
  const [tab, setTab] = React.useState("Standard");
  const [categoryStatus, setCategoryStatus] = useState(-1);

  const [customQualificationNewOpen, setCustomQualificationNewOpen] =
    useState(false);

  const ShowCustomQualificationNew = () => {
    setCustomQualificationNewOpen(true);
  };
  const CloseCustomQualificationNew = () => {
    setCustomQualificationNewOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    setPage(0);
  }, [tab]);

  let selectStyle = {
    height: "38px",
    minWidth: "200px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    
      <Grid container mt={2} mb={1}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <MDTypography sx={{ color: "black" }}>
            <span style={{ display: categoryTypeId === "All" ? "none" : "" }}>
              {"Top 10"}
            </span>{" "}
            {"Qualifications"}
          </MDTypography>
        </Grid>

        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <MDBox display="flex" alignItems="center" flexDirection="row">
              <MDBox
                className="tab"
                onClick={() => {
                  setTab("All");
                  setCheck(true);
                  setCategoryTypeId("All");
                }}
                bgColor={tab === "All" ? "white" : ""}
                color={tab === "All" && `info`}
                style={{
                  border:
                    tab === "All" ? `1px solid ${info.main}` : "1px solid rgba(217, 217, 217, 1)",

                  cursor: "pointer",
                }}
              >
                All
              </MDBox>
              <MDBox
                className="tab"
                onClick={() => {
                  setTab("Standard");
                  setCheck(false);
                  setCategoryTypeId("1");
                }}
                bgColor={tab === "Standard" ? "white" : ""}
                color={tab === "Standard" && `info`}
                style={{
                  border:
                    tab === "Standard"
                      ? `1px solid ${info.main}`
                      : "1px solid rgba(217, 217, 217, 1)",

                  cursor: "pointer",
                }}
              >
                Standard
              </MDBox>
              <MDBox
                className="tab"
                onClick={() => {
                  setTab("Custom");
                  setCheck(false);
                  setCategoryTypeId("2");
                }}
                bgColor={tab === "Custom" ? "white" : ""}
                style={{
                  border:
                    tab === "Custom"
                      ? `1px solid ${info.main}`
                      : "1px solid rgba(217, 217, 217, 1)",
                  color: tab === "Custom" && "rgba(129, 176, 60, 1)",
                  cursor: "pointer",
                }}
              >
                Custom
              </MDBox>
            </MDBox>
            <FormControl fullWidth>
              <Select
                sx={selectStyle}
                value={languageId}
                onChange={(e) => {
                  setLanguageId(e.target.value);
                }}
              >
                {language.map((value, i) => (
                  <MenuItem value={value.id} key={i}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <MDInput
              sx={{ minWidth: "200px" }}
              type="text"
              autoComplete="off"
              variant="outlined"
              label="Search"
              fullWidth
              size="small"
              required
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={1}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MDButton
            onClick={() => {
              ShowCustomQualificationNew();
            }}
            variant="contained"
            color="info"
            size="small"
          >
            + Custom
          </MDButton>
        </Grid>
      </Grid>

      <MDBox sx={{ height: "calc(100vh - 220px)", overflowY: "auto" }}>
        <Table stickyHeader>
          <TableHead style={{ display: "table-header-group" }}>
            <TableRow>
              <StyledTableHeadCell style={{ width: "2%" }} />
              <StyledTableHeadCell style={{ width: "10%" }}>
                Qualification Id
              </StyledTableHeadCell>
              <StyledTableHeadCell align="left">
                Qualification Name
              </StyledTableHeadCell>
              <StyledTableHeadCell align="left">
                Qualification Category
              </StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                Qualification Type
              </StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {qualification &&
              qualification
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <Row
                    key={row.id}
                    row={row}
                    tab={tab}
                    languageId={languageId}
                    setLanguageId={setLanguageId}
                    setIsLoading={setIsLoading}
                  />
                ))}
          </TableBody>
        </Table>
      </MDBox>
      <TablePagination
        component="div"
        count={qualification?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {customQualificationNewOpen && (
        <CustomQualificationNew
          CloseCustomQualification={CloseCustomQualificationNew}
          callApi={callApi}
          langId={1}
          setCategoryStatus={setCategoryStatus}
        />
      )}
    </>
  );
}
