import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetVendorsList = () => {
  const [vendors, setVendors] = useState([]);
  const [vendorsMap, setVendorsMap] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [totalLength, setTotalLength] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");

  const getVendors = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(
        `/vendors?search=${search}&page=${page + 1}&limit=${entriesPerPage}`
      );
      setVendors(data.data.vendorData);
      setTotalLength(data.data.totalLenght);
      setIsLoading(false);
      if (data.data.vendorData) {
        let map = [];
        data?.data?.vendorData?.map((value) => {
          map[value.id] = value.name;
          return true;
        });
        setVendorsMap(map);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    getVendors();
    // eslint-disable-next-line
  }, [page, search, entriesPerPage, sortBy, sortOrder]);
  return {
    vendors,
    getVendors,
    vendorsMap,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    setSortBy,
    setSortOrder
  };
};

export default GetVendorsList;
